<template>
  <div class="SystemOffer-list">
    <GlobalInfoBar
      title="商家列表"
      content="可查看入驻平台的商家基本信息，且可设置门店商户的自主报价回收商返利规则"
    />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <ResidentFrom />
      </div>
      <ResidentTable @handleDialog="handleDialog" @handleBtn="handleBtn" />
    </GlobalChunk>
    <DialogTbale
      @onClose="onClose"
      :show="deleteVisible"
      :title="title"
      :columns="tableColumns"
      :data="DialogDataList"
      :currentPage="pageNum"
      :total="total"
      :pageSize="10"
      :width="1500"
      @handleCurrentChange="handleCurrentChange"
    >
      <!-- 关联商家弹框新增查询条件 this.title === "关联商家"-->
      <GlobalForm
        v-if="title === '关联商家'"
        :form-item-list="unionMercItemList"
        :inline="true"
        round
        @handleConfirm="handleCurrentChange(1)"
        confirmBtnName="查询"
      >
        <el-form-item label="回收商家" slot="merChantName" style="margin: 0">
          <el-input
            clearable
            placeholder="请输入商家名称查询"
            v-model="umerChantName"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="合作状态"
          slot="cooperationState"
          style="margin: 0"
          label-width="70px"
        >
          <el-select
            v-model="cooperationState"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in cooperationStateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="是否启用服务费"
          slot="isServiceFeeOpen"
          style="margin: 0"
          label-width="110px"
        >
          <el-select
            v-model="isServiceFeeOpen"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in isServiceFeeOpenList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="el_from"
          label="收货方"
          slot="Receiver"
          style="margin: 0"
          label-width="70px"
        >
          <el-select
            v-model="Receiver"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in ReceiverList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </GlobalForm>
      <div
        v-if="title === '关联商家'"
        style="
          width: 100%;
          text-align: right;
          margin-bottom: 15px;
          padding-right: 10px;
        "
      >
        <el-button type="warning" plain size="mini" @click="bulkQuote"
          >批量设置报价品类</el-button
        >
      </div>
      <!-- ***************************** -->
      <span style="margin-bottom: 10px" v-if="showAmount">
        <el-select
          v-model="dialogValue"
          placeholder="请选择商家类型"
          @change="dialogSelect"
          style="margin-bottom: 20px"
        >
          <el-option
            v-for="item in options"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
      </span>
      <el-button
        type="primary"
        icon="el-icon-plus"
        style="margin-bottom: 20px; margin-left: 20px"
        @click="addSetting"
        v-if="title === '查看返利设置'"
      >
        新增设置
      </el-button>
      <el-table-column label="商家名称" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.merchantName }}</div>
          <div v-if="row.isInside">
            <el-tag
              type="warning"
              size="small"
              effect="plain"
              v-if="row.isInside"
              >内部回收商</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="
              row.province +
              '-' +
              row.city +
              '-' +
              row.region +
              '-' +
              row.merchantAddress
            "
            placement="top"
          >
            <p
              style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              "
            >
              {{
                row.province +
                "-" +
                row.city +
                "-" +
                row.region +
                "-" +
                row.merchantAddress
              }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="合作状态" slot="applyStatus" align="center">
        <template slot-scope="{ row }">
          <span
            >{{
              row.applyStatus == "00"
                ? "邀请待同意"
                : row.applyStatus == "01"
                ? "合作中"
                : row.applyStatus == "02"
                ? "邀请未通过"
                : "取消合作"
            }}
            {{
              row.companyIsEnable == "02" &&
              row.applyStatus != "00" &&
              row.applyStatus != "02"
                ? "(关闭报价)"
                : ""
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="合作门店"
        width="70"
        slot="configStores"
        align="center"
      >
        <template slot-scope="{ row }">
          <span
            v-if="row.configStores"
            style="
              color: #0981ff;
              border-bottom: 1px solid #0981ff;
              cursor: pointer;
            "
            @click="configStoresFn(row)"
          >
            {{ row.configStores }}
          </span>
          <span v-else>
            {{ row.configStores || "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="所属渠道"
        width="70"
        slot="Ownedchannel"
        v-if="title === '关联商家'"
        align="center"
      >
        <template slot-scope="{ row }">
          <span>
            {{ row.channelName || "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="报价品类"
        slot="quoteMachineType"
        width="160"
        align="center"
      >
        <template slot-scope="{ row }">
          <div>{{ row.quoteMachineType == "01" ? "全品类" : "指定品类" }}</div>
          <div
            v-if="
              row.quoteMachineType == '02' &&
              Array.isArray(row.assignQuoteMachineType)
            "
          >
            <el-tag
              type="success"
              style="margin-right: 5px"
              size="mini"
              v-show="row.assignQuoteMachineType.length"
              v-for="(item, index) in row.assignQuoteMachineType"
              :key="index"
            >
              {{ item }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="使用统一配置"
        slot="uniformconfiguration"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-switch
            :disabled="!currentRow.isMatrixCompany && row.isMatrix"
            inactive-color="#bebebe"
            @change="switchunification(row)"
            v-model="row.isDefaultQuoteMachine"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="物流状态"
        slot="logistics"
        width="110"
        align="center"
      >
        <template slot-scope="{ row }">
          <div
            style="color: green"
            v-if="row.merchantIsOpenLogistics && row.isOpenLogisticsToCompany"
          >
            已开启
          </div>
          <div
            style="color: red"
            v-if="row.merchantIsOpenLogistics && !row.isOpenLogisticsToCompany"
          >
            <div>未启用</div>
            <div>（商家已启用）</div>
          </div>
          <div style="color: red" v-if="!row.merchantIsOpenLogistics">
            未启用
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="receiptType" align="center">
        <template slot-scope="{ row }">
          <div style="color: #666666">
            {{ row.receiptType == "01" ? "回收商" : "平台" }}
          </div>
          <div @click="setReceiptType(row)">
            <span
              style="
                border-bottom: 1px solid #0981ff;
                color: #0981ff;
                cursor: pointer;
              "
            >
              {{ currentRow.isOut ? "修改" : "查看地址" }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="启用内部回收" slot="isInside" align="center">
        <template slot-scope="{ row }">
          <el-switch
            inactive-color="#bebebe"
            @change="switchInsideBiz(row)"
            v-model="row.isInside"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="服务费设置" slot="serverFee" align="center">
        <template slot-scope="{ row }">
          <!-- 已设置已启用 -->
          <div
            style="color: #666666"
            v-if="
              row.isSetQuoteChannelServerFee && row.isOpenQuoteChannelServerFee
            "
          >
            已设置
          </div>
          <div v-else style="color: red">
            {{ !row.isSetQuoteChannelServerFee ? "暂未设置" : "暂未启用" }}
          </div>
          <div @click="setServerFee(row)">
            <span
              style="
                border-bottom: 1px solid #0981ff;
                color: #0981ff;
                cursor: pointer;
              "
              >设置</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="是否可联系回收商"
        slot="canCallerMerchant"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-switch
            :value="row.canCallerMerchant"
            inactive-color="#bebebe"
            @change="($event) => switchCanCallerMerchant(row, $event)"
          ></el-switch>
          <div
            v-if="!row.canCallerMerchant"
            @click="switchCanCallerMerchant(row, false)"
          >
            <span
              style="
                border-bottom: 1px solid #0981ff;
                color: #0981ff;
                cursor: pointer;
              "
              >设置角色</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="预留差异基金" slot="isDiffFund" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.isOpenDiffFund == '02'" style="color: red"
            >门店未启用</span
          >
          <el-switch
            v-else
            inactive-color="#bebebe"
            @change="ReservationVariance(row)"
            v-model="row.isDiffFund"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="虚拟报价" slot="Virtualquotation" align="center">
        <template slot-scope="{ row }">
        <!--仅普通门店商户与普通回收商户可配置
         矩阵映射门店商户、外部门店商户该列的开关按钮默认禁用样式，置灰，不可点击
         普通门店商户与矩阵映射回收商，该列的按钮默认禁用样式，置灰，不可点击 -->
          <el-switch
            :disabled="currentRow.isMatrixCompany || currentRow.isOut||row.merchantIsMatrix"
            inactive-color="#bebebe"
            @change="switVirtualation(row)"
            v-model="row.isOpenMockQuote"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="140"
        fixed="right"
        slot="offerOperation"
        align="center"
      >
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            :type="row.companyIsEnable == '01' ? 'primary' : 'danger'"
            @click="offerOperation(row)"
          >
            {{ row.companyIsEnable == "01" ? "关闭报价" : "开启报价" }}
          </el-button>
          <el-button
            v-if="!row.merchantIsMatrix && !row.isDefaultQuoteMachine"
            style="margin-top: 10px"
            size="mini"
            type="warning"
            @click="setQuotecategory(row)"
          >
            设置报价品类
          </el-button>
          <el-button
            v-if="!row.merchantIsMatrix && row.isDefaultQuoteMachine"
            style="margin-top: 10px"
            size="mini"
            plain
            type="warning"
            @click="setQuotecategory(row)"
          >
            查看报价品类
          </el-button>
          <el-button
            v-if="currentRow.isOut"
            style="margin-top: 10px"
            size="mini"
            :type="row.isAssign ? 'info' : 'warning'"
            @click="setAssign(row)"
            >{{ row.isAssign ? "关闭指定报价" : "设置指定报价" }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="价格区间" slot="priceRange" align="center">
        <template slot-scope="{ row }">
          <div v-if="!row.edit">{{ row.startQuote }}-{{ row.endQuote }}</div>
          <template v-else>
            <el-input
              v-model.number="row.startQuote"
              size="mini"
              style="width: 100px"
            ></el-input>
            -
            <el-input
              v-model.number="row.endQuote"
              size="mini"
              style="width: 100px"
            ></el-input>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="苹果返利" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-radio
            name="android"
            v-model="row.iphoneType"
            label="02"
            :disabled="!row.edit"
          >
            固定金额
            <el-input
              v-if="row.edit"
              v-model.number="row.iphoneAmount"
              size="mini"
              style="width: 100px"
            ></el-input>
            <span v-else>{{ row.iphoneAmount }}</span>
          </el-radio>

          <el-radio
            name="android"
            v-model="row.iphoneType"
            label="01"
            :disabled="!row.edit"
            >按比例
            <el-input
              v-if="row.edit"
              v-model.number="row.iphoneRatio"
              size="mini"
              style="width: 50px"
            ></el-input>
            <span v-else>{{ row.iphoneRatio }}</span>
            %
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="安卓返利" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-radio
            name="android"
            v-model="row.androidType"
            label="02"
            :disabled="!row.edit"
          >
            固定金额
            <el-input
              v-if="row.edit"
              v-model.number="row.androidAmount"
              size="mini"
              style="width: 100px"
            ></el-input>
            <span v-else>{{ row.androidAmount }}</span>
          </el-radio>

          <el-radio
            name="android"
            v-model="row.androidType"
            label="01"
            :disabled="!row.edit"
            >按比例
            <el-input
              v-if="row.edit"
              v-model.number="row.androidRatio"
              size="mini"
              style="width: 50px"
            ></el-input>
            <span v-else>{{ row.androidRatio }}</span>
            %
          </el-radio>
        </template>
      </el-table-column>

      <el-table-column label="操作" slot="operate" align="center" width="150px">
        <template slot-scope="{ row }">
          <div class="dialog-btns">
            <span
              type="text"
              @click="handleEdit(row)"
              class="dialog-btn edit"
              v-if="!row.edit"
              >编辑</span
            >
            <span
              type="text"
              @click="handleSave(row)"
              class="dialog-btn save"
              v-if="row.edit"
              >保存</span
            >
            <span
              type="text"
              @click="handleDelete(row)"
              class="dialog-btn delete"
              >删除</span
            >
          </div>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 回收额度配置 -->
    <el-dialog
      title="操作提示"
      :visible.sync="AccountLimitDialogShow"
      :close-on-click-modal="false"
      width="450px"
      @close="baoDialogClose"
    >
      <div style="text-align: center; margin-bottom: 20px">
        是否确认{{
          HealthCodeCusdata.isDiffFund ? "开启" : "关闭"
        }}预留差异基金？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="AccountLimitDialogShow = false"
          >取消</el-button
        >
        <el-button type="primary" @click.native="isAccountLimitDialogSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 是否可联系回收商 弹框 -->
    <el-dialog
      title="更改设置"
      :visible.sync="canShowRealQuoteShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="canShowRealQuoteShow = false"
    >
      <div>选择不可查看商家报价的人员身份:</div>
      <div style="margin-top: 10px">
        <el-checkbox-group
          v-model="canCallerMerchantFormData.unCallerMerchantStaffTypes"
        >
          <el-checkbox
            :label="item.value"
            size="small"
            border
            v-for="item in staffTypeList"
            :key="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="canShowRealQuoteShow = false"
          >取消</el-button
        >
        <el-button type="primary" @click="btnRealQuote" size="small"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 关联商家批量设置报价品类弹框 -->
    <el-dialog
      title="批量设置报价品类"
      :visible.sync="bulkDialogShow"
      :close-on-click-modal="false"
      width="880px"
      @closed="bulkDialogClosed"
    >
      <!-- 切换按钮 -->
      <div class="top_swith">
        <div class="button_top">
          <div class="radiobtn">
            <div class="faultBtn">
              <div
                v-bind:class="bulkType === '01' ? 'left' : 'right'"
                @click="bulkBtnClick('01')"
              >
                批量开启统一报价品类
              </div>
              <div
                v-bind:class="bulkType === '02' ? 'left2' : 'right'"
                @click="bulkBtnClick('02')"
              >
                批量关闭统一报价品类
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-bottom: 15px">
        以下{{ merchantIds ? merchantIds.length : 0 }}个商家按<span
          style="color: red"
          >{{ bulkType === "01" ? "统一配置" : "个性配置" }}</span
        >推送报价品类
      </div>
      <el-table
        v-if="tableData.length"
        border
        stripe
        max-height="400"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-key="(row) => row.merchantId"
        @select="bulkHandleSelect"
        @select-all="bulkHandleSelectAll"
      >
        <el-table-column
          :reserve-selection="true"
          type="selection"
          width="55"
          align="center"
        >
        </el-table-column>
        <el-table-column type="index" label="序号" width="65" align="center">
        </el-table-column>
        <el-table-column prop="merchantName" label="回收商名称" align="center">
        </el-table-column>
        <el-table-column prop="contact" label="联系人" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" align="center">
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.length"
        style="text-align: right; margin: 20px 0"
        background
        @current-change="bulkDialogList"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="bulkPage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="bulkPage.total"
      >
      </el-pagination>
      <span v-if="tableData.length" slot="footer" class="dialog-footer">
        <el-button @click.native="bulkDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="bulkDialogSuccess"
          >确定</el-button
        >
      </span>
      <div
        v-if="tableData.length <= 0"
        style="
          width: 100%;
          text-align: center;
          padding: 80px 0px;
          font-size: 22px;
          color: #666;
          background: white;
        "
      >
        暂无数据~
      </div>
    </el-dialog>
    <DialogTbale
      @onClose="onStoresClose"
      :show="configStoreshow"
      title="门店列表"
      :columns="configStorestableColumns"
      :data="configStoresdata"
      :currentPage="StorespageNum"
      :total="Storestotal"
      :pageSize="10"
      :width="1500"
      @handleCurrentChange="handleStoresChange"
    >
      <el-table-column label="门店店长" slot="storeManager" align="center">
        <template slot-scope="{ row }">
          <!-- <el-button type="primary" size="mini" @click="FN(row)"
            >新增门店</el-button
          > -->
          <span>{{ row.storeManager || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="店长联系方式"
        slot="storeManagerPhone"
        align="center"
      >
        <template slot-scope="{ row }">
          <!-- <el-button type="primary" size="mini" @click="FN(row)"
            >新增门店</el-button
          > -->
          <span>{{ row.storeManagerPhone || "--" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-dialog
      :title="insideTitle"
      :visible.sync="insideDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="insideClosed"
    >
      <div class="enable-tip" v-if="insideType">
        <div>
          说明：启用内部回收的商家，在门店使用内部加价时需要进行加价审批。
        </div>
        <div class="tip-sure">是否确认启用该商家为门店的内部回收商？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：关闭后该门店商家员工不可再向该回收商发送内部加价申请。</div>
        <div class="tip-sure">是否不再使用该回收商作为门店的内部回收商？</div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button
          type="primary"
          :loading="insideBtnLoading"
          @click.native="insideSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="
        relationRow.companyIsEnable == '01' ? '关闭报价提示' : '开启报价提示'
      "
      :visible.sync="offerDialogShow"
      :close-on-click-modal="false"
      width="450px"
      @close="offerDialogClose"
    >
      <div
        v-if="
          currentRow.payApplyStatus != '02' ||
          relationRow.companyIsEnable == '01'
        "
      >
        <div class="enable-tip" v-if="relationRow.companyIsEnable == '01'">
          <div>说明：关闭报价后，该商家不再参与门店的报价。</div>
          <div class="tip-sure" style="text-align: left">
            是否确认关闭<span style="color: #0981ff">{{
              relationRow.merchantName
            }}</span
            >的报价？
          </div>
        </div>
        <div class="enable-tip" v-else>
          <div>说明：开启报价后，商家将继续参与门店商家的报价。</div>
          <div class="tip-sure" style="text-align: left">
            是否确认开启<span style="color: #0981ff">{{
              relationRow.merchantName
            }}</span
            >的报价？
          </div>
        </div>
      </div>
      <div
        class="enable-tip"
        style="margin-bottom: 0"
        v-if="
          relationRow.companyIsEnable == '02' &&
          currentRow.payApplyStatus == '02'
        "
      >
        <div>说明：商家已启用线上支付，请选择手续费承担方</div>
      </div>
      <el-radio-group
        v-if="
          currentRow.payApplyStatus == '02' &&
          relationRow.companyIsEnable == '02'
        "
        v-model="procedures"
        style="margin-top: 18px; margin-bottom: 22px"
      >
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            command = '';
            offerDialogShow = false;
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="offerDialogBtnLoading"
          @click.native="offerDialogSure"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- ***230906修改*** -->
    <el-dialog
      title="服务费设置"
      :visible.sync="serverFeeDialogVisible"
      :close-on-click-modal="false"
      width="900px"
      @closed="serverFeeClosed"
    >
      <div class="addparto" style="margin-bottom: 15px">
        说明：若回收商的渠道服务费开启线上结算，则使用线上结算比例自动扣款；若为线下结算，则默认按回收商报价展示渠道服务费
      </div>
      <div>
        <span style="margin-right: 14px">是否开启渠道服务费</span>
        <el-switch
          v-model="serverFeeDataList.isOpenQuoteChannelServerFee"
          :active-value="true"
          :inactive-value="false"
          active-text="开"
          inactive-text="关"
        >
        </el-switch>
      </div>
      <div
        class="fund-title"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span>渠道服务费规则</span>
        <el-button
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="addRule('add')"
          >添加规则</el-button
        >
      </div>
      <el-table
        border
        stripe
        size="small"
        :maxHeight="500"
        :data="serverFeeDataList.regionList ? serverFeeDataList.regionList : []"
        :isPagination="false"
      >
        <!-- 序号 -->
        <el-table-column label="序号" width="50" prop="index" align="center">
          <template v-slot="{ row }">
            <div>{{ row.index }}</div>
          </template>
        </el-table-column>
        <!-- 回收商原始报价 -->
        <el-table-column label="回收商原始报价" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.lower }} ≤区间&lt; {{ row.upper }}</div>
          </template>
        </el-table-column>
        <el-table-column label="服务费比例" prop="diffFund" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.matchServerFee }}%</div>
          </template>
        </el-table-column>
        <el-table-column label="服务费上限" prop="diffFundLimit" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.matchServerFeeLimit }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="{ row }">
          <el-button type="text" @click="addRule('edit', row)">编辑</el-button>
          <span class="delet-btn" @click="deleteCurrentFee(row)">删除</span>
        </el-table-column>
      </el-table>
      <div class="fund-bottom">
        <div>
          <div style="margin-bottom: 6px">
            最近修改人：{{ serverFeeDataList.updateAdminName || "--" }}
          </div>
          <div>修改时间：{{ serverFeeDataList.updateTime || "--" }}</div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click.native="serverFeeDialogVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click.native="showCommand">确定</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- ***230906修改***服务费规则设置弹框 -->
    <el-dialog
      title="添加规则"
      :visible.sync="addRuleShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="addRuleClosed"
    >
      <el-form
        :model="editPriceForm"
        :rules="editPricerules"
        ref="editPriceRulesForm"
        label-width="83px"
      >
        <el-form-item label="回收商报价:" label-width="100px">
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                placeholder="报价区间开始值"
                min="0"
                maxlength="5"
                v-model.trim="editPriceForm.lower"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input
                size="small"
                min="0"
                maxlength="5"
                placeholder="报价区间结束值"
                v-model.trim="editPriceForm.upper"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item
          label="服务费比例:"
          prop="matchServerFee"
          label-width="100px"
        >
          <el-input
            size="small"
            min="0"
            max="100"
            placeholder="输入服务费比例"
            v-model.trim="editPriceForm.matchServerFee"
            @input="limitInput($event, 'matchServerFee')"
            @blur="inputBlur('matchServerFee')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label-width="100px"
          label="服务费上限:"
          prop="matchServerFeeLimit"
        >
          <el-input
            size="small"
            placeholder="输入服务费上限"
            min="0"
            maxlength="5"
            v-model.trim="editPriceForm.matchServerFeeLimit"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addRuleShow = false">取消</el-button>
        <el-button type="primary" @click.native="addRuleSure()">确定</el-button>
      </span>
    </el-dialog>
    <!-- ***230906修改***服务费规则动态口令弹框 -->
    <el-dialog
      title="操作提示"
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="480px"
    >
      <div style="margin-bottom: 15px">是否确认修改渠道服务费？</div>
      <div class="command">
        <div>谷歌验证码：</div>
        <el-input
          v-model="serveFeeCommand"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" @click.native="setFeeSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="收货方设置"
      :visible.sync="receiptTypeDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="receiptTypeClosed"
    >
      <div class="enable-tip">
        <div>
          说明：默认回收商收货，地址取回收商配置好的地址。若为平台收货，则发货时地址为平台设置的收货地址（采货侠订单在确认成交时返回平台地址）。
        </div>
      </div>
      <div class="command">
        <div style="width: 100px">收货方设置</div>
        <el-radio-group v-model="receiptType" @change="receiptTypeChange">
          <el-radio label="01">回收商</el-radio>
          <el-radio label="02">平台</el-radio>
        </el-radio-group>
      </div>
      <div class="address-box" v-if="addressObj && receiptType == '01'">
        <div class="mb-10" style="color: #0981ff; font-size: 16px">
          收件人信息
        </div>
        <div style="margin-bottom: 5px">
          {{ addressObj.contactName }}-{{ addressObj.contactPhone }}
        </div>
        <div>
          {{ addressObj.merchantAddress
          }}{{ addressObj.houseAddress ? "-" + addressObj.houseAddress : "" }}
        </div>
      </div>
      <!-- <el-radio-group style="margin-left:34px;" v-model="receiptTypeAddress" v-if="receiptType == '02'">
        <div v-for="(item,index) in addressPlatArr" @click="changeTab(index)" :key="item.id" class="address-box" :style="{'borderColor': active == index ? '#0981FF' : 'transparent'}">
          <el-radio :label="item.id" >
           {{ item.warehouseName }}
          </el-radio>
          <div style="margin-bottom:5px;margin-top:20px;">
                {{ item.contactName }}-{{ item.contactPhone }}
              </div>
              <div>
                {{ item.platformAddress
                }}{{ item.houseAddress ? "-" + item.houseAddress : "" }}
              </div>
        </div>     
      </el-radio-group> -->
      <div style="margin-left: 34px" v-if="receiptType == '02'">
        <div
          v-for="(item, index) in addressPlatArr"
          @click="changeTab(item, index)"
          :key="item.id"
          class="address-box"
          :style="{ borderColor: item.selected ? '#0981FF' : 'transparent' }"
        >
          <div style="display: flex; align-items: center; height: 30px">
            <img
              style="width: 13px; margin-right: 5px"
              src="https://img.fanxinghuishou.cn/20231212/181882135.png"
              v-if="item.selected"
              alt=""
            />
            <img
              style="width: 13px; margin-right: 5px"
              src="https://img.fanxinghuishou.cn/20231212/266731426.png"
              v-else
              alt=""
            />
            <span
              style="font-size: 16px"
              :style="{ color: item.selected ? '#0981FF' : '#666' }"
              >{{ item.warehouseName }}</span
            >
          </div>
          <div
            style="margin-bottom: 5px"
            :style="{ color: item.selected ? '#0981FF' : '#666' }"
          >
            {{ item.contactName }}-{{ item.contactPhone }}
          </div>
          <div :style="{ color: item.selected ? '#0981FF' : '#666' }">
            {{ item.platformAddress
            }}{{ item.houseAddress ? "-" + item.houseAddress : "" }}
          </div>
        </div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="receiptTypeDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="receiptTypeSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 分发商家地址 -->
    <el-dialog
      title="查看收货地址"
      width="500px"
      :visible.sync="distributeShow"
      :close-on-click-modal="false"
      @close="distributeShow = false"
    >
      <div class="address-box" v-if="addressObj">
        <div class="mb-10" style="color: #0981ff; font-size: 16px">
          收件人信息
        </div>
        <div style="margin-bottom: 5px">
          {{ addressObj.contactName }}-{{ addressObj.contactPhone }}
        </div>
        <div>
          {{ addressObj.merchantAddress
          }}{{ addressObj.houseAddress ? "-" + addressObj.houseAddress : "" }}
        </div>
      </div>
      <div class="address-box" v-else>暂未绑定收货地址</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="distributeShow = false">取消</el-button>
        <el-button type="primary" @click.native="distributeShow = false"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="relationRow.isAssign ? '关闭指定报价商家' : '设置指定报价商家'"
      :visible.sync="setAssignShow"
      :close-on-click-modal="false"
      width="450px"
      @close="setAssignDialogClose"
    >
      <div class="enable-tip" v-if="relationRow.isAssign">
        <div>说明：关闭后外部商家不再参与同串号订单的指定报价</div>
        <div class="tip-sure">是否确定关闭该商家指定报价？</div>
      </div>
      <div class="enable-tip" v-if="!relationRow.isAssign">
        <div>
          说明：启用后外部商家同串号重新下单后，若触发了系统自动报价，在报价的同时，需要将订单推送给指定回收商报价
        </div>
        <div class="tip-sure">是否确定设置为外部渠道的指定报价商家？</div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setAssignShow = false">取消</el-button>
        <el-button
          type="primary"
          :loading="assignBtnLoading"
          @click.native="assignSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 报价品类设置 -->
    <el-dialog
      title="报价品类配置"
      :visible.sync="SetcategoryDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="receiptTypeClosed"
    >
      <div style="max-height: 480px; overflow: auto">
        <div class="dis_flex al_c set_top_ck">
          <span>选择报价分类/品牌</span>
          <div class="enable-tip" style="margin-bottom: 0; font-size: 12px">
            (说明：按照分类配置回收商报价品牌，默认全选)
          </div>
        </div>
        <div
          class="categorySet"
          v-for="(item, index) in alltypeData"
          :key="index"
        >
          <div class="cs_center dis_flex al_c ju_sb">
            <div class="selection_type">
              <img
                v-if="item.selected"
                src="https://img.fanxinghuishou.cn/20231128/086243815.png"
                alt=""
              />
              <img
                v-else
                src="https://img.fanxinghuishou.cn/20231128/808620386.png"
                alt=""
              />
              <span>{{ item.name }}</span>
            </div>
            <div
              @click="Setquemodel(item, index)"
              style="
                text-decoration: underline;
                color: #e6a23c;
                cursor: pointer;
              "
            >
              设置报价机型
            </div>
          </div>
          <div style="margin: 15px 0"></div>
          <div style="padding: 0 25px; display: flex; flex-wrap: wrap">
            <div
              class="selection_type"
              style="margin-bottom: 10px"
              v-for="(city, ctindex) in item.brands"
              :key="ctindex"
            >
              <img
                v-if="city.selected"
                src="https://img.fanxinghuishou.cn/20231128/086243815.png"
                alt=""
              />
              <img
                v-else
                src="https://img.fanxinghuishou.cn/20231128/808620386.png"
                alt=""
              />
              <span>{{ city.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck"><span>选择开机情况</span></div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox
              :indeterminate="quoteMachineConditionisIndeterminate"
              v-model="quoteMachineConditioncheckAll"
              @change="quoteMachineConditionallChange"
              >全选</el-checkbox
            >
          </div>
          <div style="margin: 15px 0"></div>
          <div style="padding: 0 25px">
            <el-checkbox-group
              v-model="quoteMachineConditioncheckedCities"
              @change="quoteMachineConditionChange"
            >
              <el-checkbox
                v-for="city in quoteMachineConditioncities"
                :label="city.value"
                :key="city.value"
                >{{ city.value }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck">
          <span>选择旧机使用情况</span>
        </div>
        <div class="user_all_cont">
          <div class="uer_content dis_flex al_c">
            <div class="left_user">
              <el-checkbox
                :indeterminate="quoteMachineHealthisIndeterminate"
                v-model="quoteMachineHealthcheckAll"
                @change="quoteMachineHealthallChange"
                >功能正常</el-checkbox
              >
            </div>
            <div class="right_uer">
              <el-checkbox-group
                v-model="quoteMachineHealthcheckedCities"
                @change="quoteMachineHealthChange"
              >
                <el-checkbox
                  v-for="city in quoteMachineHealthcities"
                  :label="city.value"
                  :key="city.value"
                  >{{ city.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="uer_content dis_flex al_c">
            <div class="left_user">
              <el-checkbox
                :indeterminate="quoteMachineHealthisIndeterminateError"
                v-model="quoteMachineHealthcheckAllError"
                @change="quoteMachineHealthallChangeError"
                >功能异常</el-checkbox
              >
            </div>
            <div class="right_uer">
              <el-checkbox-group
                v-model="quoteMachineHealthcheckedCitiesError"
                @change="quoteMachineHealthChangeError"
              >
                <el-checkbox
                  v-for="city in quoteMachineHealthcitiesError"
                  :label="city.value"
                  :key="city.value"
                  >{{ city.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck">
          <span>选择价位段</span>
        </div>
        <div class="categorySet" style="height: 120px">
          <div class="cs_center dis_flex al_c" style="height: 44px">
            <el-checkbox
              :indeterminate="quotePriceRangeHealthisIndeterminate"
              v-model="quotePriceRangeHealthcheckAll"
              @change="quotePriceRangeHealthallChange"
              >全选</el-checkbox
            >
            <div class="andleShowbox">
              <div
                :class="[
                  [
                    quotePriceType == '01'
                      ? 'andleShowcolor andleShowstyle'
                      : 'andleShowstyle',
                  ],
                ]"
                @click="switchTrigin('01')"
              >
                按靓机价
              </div>
              <div
                :class="[
                  [
                    quotePriceType !== '01'
                      ? 'andleShowcolor andleShowstyle'
                      : 'andleShowstyle',
                  ],
                ]"
                @click="switchTrigin('02')"
              >
                按预估价
              </div>
            </div>
          </div>
          <div style="margin: 15px 0"></div>
          <div style="padding: 0 25px">
            <el-checkbox-group
              v-model="quotePriceRangeHealthcheckedCities"
              @change="quotePriceRangeHealthChange"
            >
              <el-checkbox
                v-for="city in quotePriceRangeHealthcities"
                :label="city.value"
                :key="city.value"
                >{{ city.value }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; padding-bottom: 10px">
        <div style="margin-bottom: 20px">
          最近修改人：{{
            allQuteData.setQuoteAdminAcc
              ? allQuteData.setQuoteAdminAcc +
                "-" +
                allQuteData.setQuoteAdminName
              : "--"
          }}
        </div>
        <div>修改时间：{{ allQuteData.setQuoteAdminTime || "--" }}</div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="!flagDefaultMachine">
        <el-button @click.native="SetcategoryDloding = false">取消</el-button>
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="SetcategorySubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置报价机型 -->
    <el-dialog
      title="设置报价机型"
      :visible.sync="SetquotemodelDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="cloSmephone"
    >
      <el-table
        ref="qotemultipleTable"
        :data="seachDataList"
        tooltip-effect="dark"
        border
        v-loading="qutoding"
        style="width: 100%"
        @selection-change="handleSelect"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="品牌" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型选择" align="center">
          <template slot-scope="{ row }">
            <el-select
              :disabled="row.name == '其他'"
              @change="modelchange"
              v-model="row.machineRange"
              placeholder="请选择"
            >
              <el-option
                v-for="item in modelTypelist"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="指定机型" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.name == '其他'">--</div>
            <div v-else>
              <div v-if="row.machineRange !== '01'">
                <span
                  style="
                    cursor: pointer;
                    color: rgb(9, 129, 255);
                    text-decoration: underline;
                  "
                  @click="shipMachine(row)"
                  >{{ row.assignMachineIds.length }}</span
                >
                <div style="display: flex; justify-content: center">
                  <div
                    @click="Additivemodel(row)"
                    style="
                      text-decoration: underline;
                      color: #e6a23c;
                      cursor: pointer;
                    "
                  >
                    添加机型
                  </div>
                  <div
                    @click="reportitivemodel(row)"
                    style="
                      margin-left: 20px;
                      text-decoration: underline;
                      color: #67c23a;
                      cursor: pointer;
                    "
                  >
                    批量导入机型
                  </div>
                </div>
              </div>
              <div v-else>全部机型</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="cloSmephone">取消</el-button>
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="SetquotemodelSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加报价机型 -->
    <el-dialog
      :title="
        setionBranddata.machineRange == '02' ? '添加报价机型' : '添加不报价机型'
      "
      :visible.sync="AddquotemodelDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="(AddquotemodelDloding = false), (formInline.phoneName = '')"
    >
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input
              v-model="formInline.phoneName"
              placeholder="请输入机型名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="serchModalSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="addtipleTable"
        :data="opymodelList"
        tooltip-effect="dark"
        border
        max-height="530"
        style="width: 100%"
        @selection-change="modelleSelect"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{ row.channel || "--" }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="
            (AddquotemodelDloding = false), (formInline.phoneName = '')
          "
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="AddmodelSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 删除报价机型 -->
    <el-dialog
      title="管理报价机型"
      :visible.sync="deletequotemodelDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="deletequotemodelDloding = false"
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-item: center;
        "
      >
        <el-form :inline="true" :model="deteleformIn" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input
              v-model="deteleformIn.phoneName"
              placeholder="请输入机型名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="deteleserchSubmit"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <el-button
            type="success"
            icon="el-icon-download"
            size="medium"
            @click="download(1)"
            >导出机型</el-button
          >
          <el-button
            @click="detaleAllphone"
            v-if="deteleIdsList.length > 0"
            :key="90"
            type="danger"
            >批量删除</el-button
          >
          <el-button type="info" v-else :key="80">批量删除</el-button>
        </div>
      </div>
      <el-table
        ref="addtipleTable"
        :data="detepymodelList"
        tooltip-effect="dark"
        border
        max-height="530"
        style="width: 100%"
        @selection-change="deteleModeleSelect"
      >
        <el-table-column type="selection" align="center"> </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.name || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{ row.channel || "--" }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{ row }">
            <el-button
              type="danger"
              size="small"
              round
              @click="detaleRedyphone(row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="deletequotemodelDloding = false"
          >关闭</el-button
        >
        <!-- <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="detelemodelSubmit"
          >确定</el-button
        > -->
      </span>
    </el-dialog>
    <!-- 导入指定机型 -->
    <el-dialog
      class="add-dialog"
      :title="
        setionBranddata.machineRange == '02' ? '导入报价机型' : '导入不报价机型'
      "
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      width="510px"
      @closed="exportinDialogClosed"
    >
      <div class="step">
        <div class="step-title" v-if="setionBranddata.machineRange == '02'">
          <span
            >说明：需先下载机型数据，将挑选后的机型数据上传表格，表格中的机型ID不可修改</span
          >
        </div>
        <div class="step-title" v-if="setionBranddata.machineRange == '03'">
          <span
            >说明：需先下载机型数据，将回收商不报价的机型数据上传表格，表格中的机型ID不可修改</span
          >
        </div>
        <div class="step-content">
          <el-button
            type="success"
            icon="el-icon-download"
            size="medium"
            @click="download"
            >下载数据模板</el-button
          >
        </div>
        <el-form
          ref="importForm"
          :model="importForm"
          :rules="importRules"
          label-width="80px"
        >
          <el-form-item label="表格上传" prop="fileName" class="fileName">
            <el-input
              v-model="importForm.fileName"
              @clear="fileClear"
              :disabled="true"
              size="medium"
              clearable
            >
            </el-input>
            <div style="float: right; position: relative">
              <el-button size="medium" type="warning" icon="el-icon-upload2"
                >上传表格</el-button
              >
              <input
                accept=".xls, .xlsx"
                ref="fileUpload"
                @change="onUploadList"
                type="file"
                style="
                  opacity: 0;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  cursor: pointer;
                "
              />
            </div>
            <div style="color: #ff687b">
              仅支持.xls及.xlsx格式,单次上传大小不超过5M
            </div>
          </el-form-item>
        </el-form>
        <div class="step2-content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" @click.native="uploadFile">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看报价机型 -->
    <el-dialog
      title="查看报价机型"
      :visible.sync="seaquotemodelDloding"
      :close-on-click-modal="false"
      width="1000px"
      @closed="seaquotemodelDloding = false"
    >
      <div>
        <el-form :inline="true" :model="seaformIn" class="demo-form-inline">
          <el-form-item label="机型名称">
            <el-input
              v-model="seaformIn.phoneName"
              placeholder="请输入机型名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="SeamodelSubmit">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="addtipleTable"
        :data="seaOymodelList"
        tooltip-effect="dark"
        border
        max-height="540"
        style="width: 100%"
      >
        <el-table-column label="序号" align="center" type="index" width="50">
        </el-table-column>
        <el-table-column label="所属品牌" align="center">
          <template slot-scope="{ row }">{{ row.brandName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型名称" align="center">
          <template slot-scope="{ row }">{{ row.phnoeName || "--" }}</template>
        </el-table-column>
        <el-table-column label="机型来源" align="center">
          <template slot-scope="{ row }">{{
            row.modelSource || "--"
          }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="seaquotemodelDloding = false">取消</el-button>
      </span>
    </el-dialog>
    <!-- 保存报价 -->
    <el-dialog
      title="提示"
      :visible.sync="quotationDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div style="text-align: center; font-size: 16px; margin-bottom: 20px">
        是否确认保存报价配置？
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="quotationDialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="receiptTypeBtnLoading"
          @click.native="quotationSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 虚拟报价配置 -->
    <el-dialog
      :title="VirtualMachineTitle"
      :visible.sync="VirtualDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="VirtualMachineClosed"
    >
      <div class="enable-tip" v-if="isOpenMockQuote">
        <div>
          说明：启用后，该回收商超时未报价或拒绝报价后，门店将展示虚拟报价，虚拟报价无法成交。
        </div>
        <div class="tip-sure">
          是否确认启用<span style="color: rgb(9, 129, 255)">{{
            unifytName
          }}</span
          >在该门店商家下的虚拟报价功能？
        </div>
      </div>
      <div class="enable-tip" v-else>
        <div>
          说明：关闭后，该回收商超时未报价或拒绝报价后，系统不再虚拟价格
        </div>
        <div class="tip-sure">
          是否确认关闭<span style="color: rgb(9, 129, 255)">{{
            unifytName
          }}</span
          >在该门店商家下的虚拟报价功能？
        </div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="VirtualMachineClosed">取消</el-button>
        <el-button
          type="primary"
          :loading="insideBtnLoading"
          @click.native="VirtualMachineSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 统一配置 报价品类-->
    <el-dialog
      :title="QuoteMachineTitle"
      :visible.sync="QuoteMachineDialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="QuoteMachineClosed"
    >
      <div class="enable-tip" v-if="isDefaultQuoteMachine">
        <div>说明：启用后，使用该回收商统一配置的报价品类。</div>
        <div class="tip-sure">
          是否确认开启使用{{ unifytName }}统一报价配置功能？
        </div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：关闭后，使用单独回收商在该门店商单独配置的报价品类。</div>
        <div class="tip-sure">
          是否确认关闭使用{{ unifytName }}统一报价配置功能？
        </div>
      </div>
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="QuoteMachineClosed">取消</el-button>
        <el-button
          type="primary"
          :loading="insideBtnLoading"
          @click.native="QuoteMachineSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 批量配置报价品类动态口令-->
    <el-dialog
      title="提示"
      :visible.sync="BatchconfigurationVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="(BatchconfigurationVisible = false), (command = '')"
    >
      <div class="command">
        <div style="width: 85px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click.native="(BatchconfigurationVisible = false), (command = '')"
          >取消</el-button
        >
        <el-button
          type="primary"
          :loading="insideBtnLoading"
          @click.native="BatchconfigurationSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ResidentFrom from "./From";
import ResidentTable from "./Table";
import { confirm } from "@/components/common/messagebox";
export default {
  name: "SystemOffer-list",
  components: { ResidentFrom, ResidentTable },
  data() {
    let checkNum = (rule, value, callback) => {
      if (value == 0 || value >= 100) {
        callback(new Error("服务费比例必须大于0小于100"));
      } else {
        callback();
      }
    };
    return {
      BatchconfigurationVisible: false,
      // 关联商家批量设置报价品类弹框
      bulkPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      merchantIds: [], //选择的回收商id
      bulkType: "01", //'01'开启；'02'关闭
      bulkDialogShow: false,
      tableData: [],
      // 关联商家弹框新增查询条件
      unionMercItemList: [
        { slotName: "merChantName" },
        { slotName: "cooperationState" },
        { slotName: "isServiceFeeOpen" },
        { slotName: "Receiver" },
      ],
      cooperationState: "",
      umerChantName: "", //回收商家
      isServiceFeeOpen: null, //是否启用服务费
      Receiver: "", //收货方
      cooperationStateList: [
        {
          name: "合作中",
          id: "01",
        },
        {
          name: "关闭报价",
          id: "02",
        },
        {
          name: "邀请待同意",
          id: "00",
        },
      ],
      isServiceFeeOpenList: [
        {
          name: "已启用",
          id: true,
        },
        {
          name: "未启用",
          id: false,
        },
      ],
      ReceiverList: [
        {
          name: "平台",
          id: "02",
        },
        {
          name: "回收商",
          id: "01",
        },
      ],
      // ****************
      tupeIndex: "",
      alltypeData: [], //所有数据
      quotePriceType: null,
      formInline: {
        phoneName: "",
      },
      seaformIn: {
        phoneName: "",
      },
      deteleformIn: {
        phoneName: "",
      },
      modelIdsList: [],
      brandIdsList: [],
      deteleIdsList: [],
      modelTypelist: [
        {
          value: "01",
          label: "全部机型",
        },
        {
          value: "02",
          label: "指定报价机型",
        },
        {
          value: "03",
          label: "指定不报价机型",
        },
      ],
      seachDataList: [],
      detalemodelList: [], //管理已添加机型数据备份
      modelDataList: [], //添加机型数据备份
      seaDataList: [],
      opymodelList: [], //添加机型列表展示
      seaOymodelList: [],
      detepymodelList: [], //管理机型数据展示
      // 规则
      editPricerules: {
        matchServerFee: [
          { required: true, message: "请输入服务费比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        matchServerFeeLimit: [
          {
            required: true,
            message: "请输入服务费上限",
            trigger: "blur",
          },
        ],
      },
      // 服务费设置在
      feeMerchantId: "", //回收商户id
      feeCompanyId: "", //门店商户id
      serverFeeDataList: [], //服务费规则数据
      addRuleShow: false, //添加/编辑规则弹框
      ruleTitle: "", //规则标题
      editPriceForm: {
        //服务费添加规则传参
        lower: "",
        upper: "",
        matchServerFee: "",
        matchServerFeeLimit: "",
      },
      commandShow: false, //动态口令弹框
      serveFeeCommand: "", //动态口令
      // *****************************
      unifytName: "",
      allQuteData: {},
      contactmerchantId: "",
      quotationDialogVisible: false,
      //选择开机机况
      quoteMachineConditioncheckAll: false,
      quoteMachineConditioncheckedCities: [],
      quoteMachineConditioncities: [],
      quoteMachineConditionisIndeterminate: true,
      quoteMachineConditionOptions: [],
       //选择旧机使用情况正常
       quoteMachineHealthcheckAll: false,
      quoteMachineHealthcheckedCities: [],
      quoteMachineHealthcities: [],
      quoteMachineHealthisIndeterminate: true,
      quoteMachineHealthOptions: [],
        //选择旧机使用情况异常
      quoteMachineHealthcheckAllError: false,
      quoteMachineHealthcheckedCitiesError: [],
      quoteMachineHealthcitiesError: [],
      quoteMachineHealthisIndeterminateError: true,
      quoteMachineHealthOptionsError: [],
      //选择价位段
      quotePriceRangeHealthcheckAll: false,
      quotePriceRangeHealthcheckedCities: [],
      quotePriceRangeHealthcities: [],
      quotePriceRangeHealthisIndeterminate: true,
      quotePriceRangeHealthOptions: [],
      //报价品类配置弹窗
      SetcategoryDloding: false,
      //设置报价机型
      SetquotemodelDloding: false,
      //添加报价机型
      AddquotemodelDloding: false,
      //管理报价机型
      deletequotemodelDloding: false,
      //查看报价机型
      seaquotemodelDloding: false,
      machineTypeId: "", //分类ID
      setionBrandId: "", //单个品牌id
      setionBranddata: {},
      savemodelType: "", //分类名称
      copyallmodelData: "", //分类所有数据留底
      qutoding: false,
      // 是否统一使用报价机型配置
      flagDefaultMachine: true,
      // 分发弹窗
      distributeShow: false,
      clustersofstars: "",
      StoresmerchantId: "",
      StorespageNum: 1,
      Storestotal: 0,
      configStoreshow: false,
      showAmount: false,
      companyName: "",
      contact: "",
      phone: "",
      //弹窗下拉
      options: [],
      dialogValue: "",
      navShow: false,
      deleteVisible: false,
      title: "",
      DialogDataList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      companyId: "",
      merchantId: "",
      merchantCompanyId: "",
      tableColumns: [],
      configStorestableColumns: [
        { label: "门店名称", prop: "storeName" },
        { label: "门店地址", prop: "storeAddress" },
        { slotName: "storeManager" },
        { slotName: "storeManagerPhone" },
        { label: "店员数量", prop: "staffCount" },
        { label: "添加时间", prop: "createTime" },
      ],
      configStoresdata: [],
      insideType: null,
      isDefaultQuoteMachine: null,
      isOpenMockQuote: null,
      insideTitle: "",
      QuoteMachineTitle: "",
      VirtualMachineTitle: "",
      rowCId: "",
      rowMId: "",
      insideDialogVisible: false,
      QuoteMachineDialogVisible: false,
      VirtualDialogVisible: false,
      AccountLimitDialogShow: false,
      command: "",
      insideBtnLoading: false,
      offerDialogShow: false,
      procedures: "",
      currentRow: {},
      relationRow: {},
      offerDialogBtnLoading: false,
      serverFeeDialogVisible: false,
      serverFeeBtnLoading: false,
      receiptTypeBtnLoading: false,
      isShowQuoteChannelServerFee: false,
      quoteChannelServerFee: "",
      receiptTypeDialogVisible: false,
      receiptType: "01",
      receiptTypeAddress: 1,
      addressPlatArr: [],
      addressObj: null,
      assignBtnLoading: false,
      setAssignShow: false,
      active: "",
      importVisible: false,
      paramsExcel: {},
      importForm: {
        fileName: "",
      },
      importRules: {
        fileName: [
          {
            required: true,
            message: "仅支持.xls及.xlsx格式,单次上传大小不超过5M",
            trigger: "blur",
          },
        ],
      },

      canShowRealQuoteShow: false,
      canCallerMerchantFormData: {
        canCallerMerchant: true,
        companyId: 0,
        merchantId: 0,
        unCallerMerchantStaffTypes: [],
      },
      staffTypes: [],
      staffTypeList: [
        {
          value: "01",
          label: "创建人子账号",
        },
        {
          value: "02",
          label: "总监",
        },
        {
          value: "03",
          label: "店长",
        },
        {
          value: "04",
          label: "店员",
        },
      ],
      HealthCodeCusdata: {},
    };
  },
  created() {
    this.$store.commit("tagsView/ROUTER_PATH", this.$route.path);
    // this.seaOymodelList = this.seaDataList;
  },
  computed: {
    dialogParams() {
      return this.$store.state.tagsView.dialogParams;
    },
  },
  methods: {
    baoDialogClose(){
      this.handleCurrentChange(1)
    },
    ReservationVariance(row) {
      this.HealthCodeCusdata = row;
      this.AccountLimitDialogShow = true;
    },
    //预留差异基金配置
    isAccountLimitDialogSure() {
      let params = {
        isDiffFund: this.HealthCodeCusdata.isDiffFund,
        merchantId: this.HealthCodeCusdata.merchantId,
        companyId:this.HealthCodeCusdata.companyId
      };
      _api.updatedifffund(params).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.handleCurrentChange(1)
          this.AccountLimitDialogShow = false;
        }
      });
    },
    // 弹窗关闭
    exportinDialogClosed() {
      if (this.$refs["addForm"]) {
        this.$refs["addForm"].resetFields();
      } else {
        this.$refs["importForm"].resetFields();
      }
      this.importForm = {
        fileName: "",
      };
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    //表格清理
    fileClear() {
      this.$refs.fileUpload.value = "";
      this.paramsExcel = {};
    },
    onUploadList(event, type) {
      console.log(event);
      this.paramsExcel.file = event.target.files;
      if (this.paramsExcel.file.length) {
        this.paramsExcel.excelName = event.target.files[0].name;
        this.importForm.fileName = event.target.files[0].name;
      }
    },
    // 确定导出
    uploadFile() {
      if (!this.paramsExcel.file || this.paramsExcel.file.length == 0) {
        return this.$message.error("请上传表格");
      }
      const fd = new FormData();
      console.log(this.paramsExcel);
      fd.append("file", this.paramsExcel.file[0]); //文件
      fd.append("filterMachineModelIds", this.setionBranddata.assignMachineIds); //文件
      fd.append("brandId", this.setionBranddata.id); //文件
      fd.append("machineTypeId", this.machineTypeId); //文件
      console.log(fd);
      this.repeatData = [];
      // 发起请求
      _api
        .importAssignMachine(fd)
        .then((res) => {
          console.log("_RequestUploads_", res);
          if (res.code === 1) {
            this.importForm.fileName = "";
            this.$refs.fileUpload.value = "";
            if (res.data.length > 0) {
              let modids = [];
              res.data.forEach((item) => {
                modids.push(item.id);
              });
              this.seachDataList.forEach((item) => {
                if (this.setionBrandId === item.id) {
                  item.assignMachineIds.push(...modids);
                  let resetdata = new Set(item.assignMachineIds);
                  item.assignMachineIds = [...resetdata];
                }
              });
              this.$message({
                type: "success",
                message: "导入成功！",
              });
            } else {
              this.$message({
                type: "warning",
                message: "导入机型为空！",
              });
            }
            this.importVisible = false;
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.importForm.fileName = "";
          this.$refs.fileUpload.value = "";
        });
    },
    //下载模板
    download(data) {
      let params = {
        assignMachineIdList: null,
        brandId: this.setionBranddata.id,
        machineTypeId: this.machineTypeId,
      };
      if (data == 1) {
        console.log(data);
        params.assignMachineIdList = this.setionBranddata.assignMachineIds;
      }
      _api
        .importModelByTypeBrand(params)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                this.$message.error(data.msg || "导出失败");
              }
            } catch (err) {
              const fileName = "指定机型列表.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
          this.codeLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codeLoading = false;
        });
    },
    // 批量设置报价品类-打开弹框
    bulkQuote() {
      this.bulkDialogShow = true;
      this.bulkPage.pageNum = 1;
      this.merchantIds = [];
      this.tableData = [];
      this.bulkDialogList();
    },
    //  批量开启/关闭统一报价品类切换
    bulkBtnClick(type) {
      this.bulkType = type;
      this.bulkPage.pageNum = 1;
      this.merchantIds = [];
      this.tableData = [];
      this.bulkDialogList();
    },
    //  请求批量设置报价品类数据列表
    bulkDialogList(val) {
      if (val) {
        this.bulkPage.pageNum = val;
      }
      let isDefaultQuoteMachine = this.bulkType === "01" ? false : true;
      let params = {
        isFilterMerchantMatrix: true, //写死
        companyId: this.currentRow.id,
        pageNum: this.bulkPage.pageNum,
        pageSize: this.bulkPage.pageSize,
        isDefaultQuoteMachine: isDefaultQuoteMachine, //是否统一使用报价机型配置
      };
      _api.unionMerchantList(params).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.records;
          this.bulkPage.pageNum = res.data.current;
          this.bulkPage.total = res.data.total;
        }
      });
    },
    // 多选
    bulkHandleSelect(val) {
      console.log(val, "val88888");
      this.merchantIds = val.map((obj) => obj.merchantId);
      console.log(this.merchantIds, "this.merchantIds");
    },
    // 全选
    bulkHandleSelectAll(val) {
      console.log(val, "val9999");
      this.merchantIds = val.length ? val.map((obj) => obj.merchantId) : null;
      console.log(this.merchantIds, "this.merchantIds");
    },
    // 关闭
    bulkDialogClosed() {
      this.bulkDialogShow = false;
      this.merchantIds = [];
      this.tableData = [];
    },
    BatchconfigurationSubmit() {
      let isDefaultQuoteMachine = this.bulkType === "01" ? true : false;
      let params = {
        command: this.command,
        companyId: this.currentRow.id,
        isDefaultQuoteMachine: isDefaultQuoteMachine,
        merchantIds: this.merchantIds,
      };
      _api.openDefaultQuoteBatch(params).then((res) => {
        if (res.code === 1) {
          this.$message({
            message: res.msg || "操作成功",
            type: "success",
          });
          this.command = "";
          this.BatchconfigurationVisible = false;
          this.bulkDialogShow = false;
          this.merchantIds = [];
          this.tableData = [];
          this.unionDialog(); //刷新
        }
      });
    },
    // 确定--请求接口是否统一使用报价机型配置
    bulkDialogSuccess() {
      if (this.merchantIds.length === 0) {
        return this.$message.error("请选择要操作的回收商！");
      }
      this.BatchconfigurationVisible = true;
    },
    //管理已添加机型
    shipMachine(row) {
      this.deteleformIn.phoneName = "";
      console.log(row.assignMachineIds);
      this.setionBrandId = row.id;
      this.setionBranddata = row;
      let addparams = {
        assignMachineModelIds: row.assignMachineIds,
        brandId: row.id,
        type: "02", //类型（全部：00,未添加：01，已添加：02）
        machineTypeId: this.machineTypeId,
      };
      //查看需添加机型
      _api.listByTypeBrand(addparams).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.deletequotemodelDloding = true;
          this.detalemodelList = JSON.parse(JSON.stringify(res)).data;
          this.detepymodelList = JSON.parse(JSON.stringify(res)).data;
        }
      });
    },
    //导入机型
    reportitivemodel(row) {
      console.log(row);
      this.setionBrandId = row.id;
      this.setionBranddata = row;
      this.importVisible = true;
    },
    //添加机型
    Additivemodel(row) {
      console.log(row.assignMachineIds);
      this.setionBrandId = row.id;
      this.setionBranddata = row;
      let addparams = {
        assignMachineModelIds: row.assignMachineIds,
        brandId: row.id,
        type: "01", //类型（全部：00,未添加：01，已添加：02）
        machineTypeId: this.machineTypeId,
      };
      //查看需添加机型
      _api.listByTypeBrand(addparams).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.AddquotemodelDloding = true;
          this.modelDataList = JSON.parse(JSON.stringify(res)).data;
          this.opymodelList = JSON.parse(JSON.stringify(res)).data;
        }
      });
    },
    switchTrigin(type) {
      this.quotePriceType = type;
    },
    //设置报价机型
    Setquemodel(data, index) {
      //保存分类
      this.tupeIndex = index;
      this.savemodelType = data.type;
      console.log(data);
      this.seachDataList = data.brands;
      this.machineTypeId = data.id;
      this.SetquotemodelDloding = true;
      this.qutoding = true;
      // 回显选中品牌
      this.$nextTick(() => {
        this.seachDataList.forEach((item) => {
          if (item.selected) {
            this.$refs.qotemultipleTable.toggleRowSelection(item, true);
          }
        });
      });
      this.$forceUpdate();
      setTimeout(() => {
        this.qutoding = false;
      }, 800);
    },
    //报价机型模糊查询
    serchModalSubmit() {
      console.log(this.formInline.phoneName);
      this.opymodelList = [];
      if (this.formInline.phoneName) {
        this.modelDataList.forEach((item) => {
          if (
            item.name
              .toLowerCase()
              .includes(this.formInline.phoneName.toLowerCase())
          ) {
            this.opymodelList.push(item);
          }
        });
      } else {
        this.opymodelList = this.modelDataList;
      }
    },
    //查看报价机型查看
    SeamodelSubmit() {
      console.log(this.seaformIn.phoneName);
      this.seaOymodelList = [];
      if (this.seaformIn.phoneName) {
        this.seaDataList.forEach((item) => {
          if (
            item.phnoeName
              .toLowerCase()
              .includes(this.seaformIn.phoneName.toLowerCase())
          ) {
            this.seaOymodelList.push(item);
          }
        });
      } else {
        this.seaOymodelList = this.seaDataList;
      }
    },
    //删除报价机型模糊查询
    deteleserchSubmit() {
      console.log(this.deteleformIn.phoneName);
      this.detepymodelList = [];
      if (this.deteleformIn.phoneName) {
        this.detalemodelList.forEach((item) => {
          if (
            item.name
              .toLowerCase()
              .includes(this.deteleformIn.phoneName.toLowerCase())
          ) {
            this.detepymodelList.push(item);
          }
        });
      } else {
        this.detepymodelList = this.detalemodelList;
      }
    },
    //删除机型
    detaleRedyphone(id) {
      //删除展示数据
      this.detepymodelList.forEach((item, index) => {
        if (id === item.id) {
          this.detepymodelList.splice(index, 1);
        }
      });
      //同步此分类总数据
      this.seachDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          item.assignMachineIds.forEach((itemhave, haveindex) => {
            if (itemhave === id) {
              item.assignMachineIds.splice(haveindex, 1);
            }
          });
        }
      });
      this.deletequotemodelDloding = false;
    },
    //批量删除机型
    detaleAllphone() {
      //删除展示数据
      this.deteleIdsList.forEach((item) => {
        this.detepymodelList.forEach((items, index) => {
          if (item.id === items.id) {
            this.detepymodelList.splice(index, 1);
          }
        });
      });
      //同步此分类总数据
      this.seachDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          this.deteleIdsList.forEach((itemde) => {
            item.assignMachineIds.forEach((itemhave, haveindex) => {
              if (itemhave === itemde.id) {
                item.assignMachineIds.splice(haveindex, 1);
              }
            });
          });
        }
      });
      this.deletequotemodelDloding = false;
    },
    //管理机型
    detelemodelSubmit() {
      this.deletequotemodelDloding = false;
    },
    //选择已选管理机型
    deteleModeleSelect(data) {
      console.log(data);
      this.deteleIdsList = data;
    },
    modelchange(e) {
      console.log(e);
    },
    //取消保存设置报价机型
    cloSmephone() {
      this.$nextTick(() => {
        this.$refs.qotemultipleTable.clearSelection();
      });
      this.SetquotemodelDloding = false;
    },
    //保存设置报价机型
    SetquotemodelSubmit() {
      //更新外层数据
      this.seachDataList.forEach((item) => {
        item.selected = false;
      });
      this.brandIdsList.forEach((item) => {
        this.seachDataList.forEach((itemsd) => {
          if (item.id === itemsd.id) {
            itemsd.selected = true;
          }
        });
      });
      let actionARR = [];
      actionARR = this.seachDataList.filter((item) => item.selected);
      console.log(this.seachDataList, this.tupeIndex);
      if (actionARR.length !== this.seachDataList.length) {
        this.allQuteData.typeList[this.tupeIndex].selected = false;
      } else {
        this.allQuteData.typeList[this.tupeIndex].selected = true;
      }
      this.$nextTick(() => {
        this.$refs.qotemultipleTable.clearSelection();
      });
      this.$forceUpdate();
      console.log(this.allQuteData);
      this.SetquotemodelDloding = false;
    },
    //添加报价机型
    AddmodelSubmit() {
      if (this.modelIdsList.length === 0) {
        return this.$message.error("请选择报价机型！");
      }
      let modids = [];
      this.modelIdsList.forEach((item) => {
        modids.push(item.id);
      });
      this.seachDataList.forEach((item) => {
        if (this.setionBrandId === item.id) {
          item.assignMachineIds.push(...modids);
        }
      });
      this.formInline.phoneName = "";
      this.AddquotemodelDloding = false;
    },
    //批量选择品牌
    handleSelect(data) {
      console.log(data);
      this.brandIdsList = data;
    },
    //批量选择机型
    modelleSelect(data) {
      console.log(data);
      this.modelIdsList = data;
      // console.log(this.modelIdsList);
    },
    // 规则
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = "";
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有1位小数
      let valJoin = value.join("");
      // this.editPriceForm[name] =value.replace(/[^0-9]/g,'')
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,1})/g)[0] || "";
      if (this.editPriceForm[name] >= 100) {
        this.editPriceForm[name] = "100";
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (
        this.editPriceForm[name].toString().indexOf(".") ==
        this.editPriceForm[name].length - 1
      ) {
        this.editPriceForm[name] = this.editPriceForm[name].replace(".", "");
      }
    },
    // 设置服务费弹框
    setServerFee(row) {
      (this.feeMerchantId = row.merchantId),
        (this.feeCompanyId = row.companyId);
      this.serverFeeDialogVisible = true;
      // 请求需求
      this.getServerFeeDetail(row.companyId, row.merchantId);
    },
    // 关闭服务费弹框
    serverFeeClosed() {},
    // 服务费详情
    getServerFeeDetail(companyId, merchantId) {
      const params = {
        companyId: companyId,
        merchantId: merchantId,
      };
      _api.getServerFeeDetail(params).then((res) => {
        this.serverFeeDataList = res.data ? res.data : [];
        this.serverFeeDataList.regionList =
          this.serverFeeDataList.regionList.map((v, index) => {
            return {
              ...v,
              index: index + 1,
            };
          });
      });
    },
    // 删除当前行服务费规则
    deleteCurrentFee(row, event, column) {
      console.log(row, "4444444444");
      const rowIndex = this.serverFeeDataList.regionList.indexOf(row);
      console.log(rowIndex, "当前行index");
      this.serverFeeDataList.regionList.splice(rowIndex, 1);
    },
    // 添加规则
    addRule(type, row) {
      this.addRuleShow = true;
      const { regionList } = this.serverFeeDataList;
      if (type === "add") {
        //添加规则
        this.ruleTitle = "添加规则";
        //重置数据
        this.editPriceForm = {};
      } else {
        //编辑规则
        this.ruleTitle = "编辑规则";
        //重置数据
        this.editPriceForm = row;
      }
    },
    // 关闭规则
    addRuleClosed() {
      this.addRuleShow = false;
    },
    // 规则确定
    addRuleSure() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          if (!this.editPriceForm.lower || !this.editPriceForm.upper) {
            this.$message.error("请填写回收商报价区间！");
            return;
          }
          if (
            this.editPriceForm.lower &&
            this.editPriceForm.upper &&
            Number(this.editPriceForm.upper) <= Number(this.editPriceForm.lower)
          ) {
            this.$message.error("报价区间结束值必须大于报价区间开始值！");
            return;
          }
          // *****
          this.addRuleShow = false;
          const { matchServerFee, matchServerFeeLimit, lower, upper } =
            this.editPriceForm;
          // 123≤区间<1231
          this.addRuleShow = false;
          if (this.ruleTitle === "添加规则") {
            this.serverFeeDataList.regionList.push({
              ...this.editPriceForm,
              region: `${lower}≤区间<${upper}`,
              index: this.serverFeeDataList.regionList.length + 1,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 打开动态口令弹框
    showCommand() {
      this.commandShow = true;
    },
    // 保存服务费设置确定
    setFeeSure() {
      let regionList = this.serverFeeDataList.regionList;
      const params = {
        companyId: this.feeCompanyId,
        merchantId: this.feeMerchantId,
        command: this.serveFeeCommand,
        isOpenQuoteChannelServerFee:
          this.serverFeeDataList.isOpenQuoteChannelServerFee,
        regionList: regionList,
      };
      _api.setChannelServerFee(params).then((res) => {
        console.log(res);
        if (res.code === 1) {
          // 刷新列表
          this.handleCurrentChange();
          this.commandShow = false;
          this.serverFeeDialogVisible = false;
          this.serveFeeCommand = "";
        }
      });
    },
    // ********************
    //设置报价品类
    setQuotecategory(row) {
      this.contactmerchantId = row.merchantId;
      // 是否统一使用报价机型配置
      this.flagDefaultMachine = row.isDefaultQuoteMachine;
      this.getQuoteAssignTemp();
    },
    //获取报价配置模板
    getQuoteAssignTemp() {
      //清空
      this.resetalllist();
      _api
        .getQuoteAssignTemp({
          companyId: this.clustersofstars, //用商家列表的
          merchantId: this.contactmerchantId, //用关联商家的
        })
        .then((res) => {
          //数据留底
          this.alltypeData = res.data.typeList;
          //数据留底
          this.copyallmodelData = JSON.parse(JSON.stringify(res)).data;
          this.allQuteData = res.data;
          this.quotePriceType = res.data.quotePriceType;
          //选择报价机况
          this.quoteMachineConditioncities = res.data.quoteMachineCondition;
          res.data.quoteMachineCondition.forEach((item) => {
            this.quoteMachineConditionOptions.push(item.value);
            if (item.selected) {
              this.quoteMachineConditioncheckedCities.push(item.value);
            }
          });
        //选择旧机使用情况正常
        this.quoteMachineHealthcities = res.data.quoteMachineHealthNormal;
          res.data.quoteMachineHealthNormal.forEach((item) => {
            this.quoteMachineHealthOptions.push(item.value);
            if (item.selected) {
              this.quoteMachineHealthcheckedCities.push(item.value);
            }
          });
            //选择旧机使用情况异常
          this.quoteMachineHealthcitiesError = res.data.quoteMachineHealthError;
          res.data.quoteMachineHealthError.forEach((item) => {
            this.quoteMachineHealthOptionsError.push(item.value);
            if (item.selected) {
              this.quoteMachineHealthcheckedCitiesError.push(item.value);
            }
          });
          //选择价位段
          this.quotePriceRangeHealthcities = res.data.quotePriceRange;
          res.data.quotePriceRange.forEach((item) => {
            this.quotePriceRangeHealthOptions.push(item.value);
            if (item.selected) {
              this.quotePriceRangeHealthcheckedCities.push(item.value);
            }
          });
          this.SetcategoryDloding = true;
        });
    },
    //清空报价品类
    resetalllist() {
      //选择报价机况
      this.quoteMachineConditioncheckAll = false;
      this.quoteMachineConditioncheckedCities = [];
      this.quoteMachineConditioncities = [];
      this.quoteMachineConditionisIndeterminate = true;
      this.quoteMachineConditionOptions = [];
      //选择报价机况正常
      this.quoteMachineHealthcheckAll = false;
      this.quoteMachineHealthcheckedCities = [];
      this.quoteMachineHealthcities = [];
      this.quoteMachineHealthisIndeterminate = true;
      this.quoteMachineHealthOptions = [];
        //选择报价机况异常
      this.quoteMachineHealthcheckAllError = false;
      this.quoteMachineHealthcheckedCitiesError = [];
      this.quoteMachineHealthcitiesError = [];
      this.quoteMachineHealthisIndeterminateError = true;
      this.quoteMachineHealthOptionsError = [];
      //选择价位段
      this.quotePriceRangeHealthcheckAll = false;
      this.quotePriceRangeHealthcheckedCities = [];
      this.quotePriceRangeHealthcities = [];
      this.quotePriceRangeHealthisIndeterminate = true;
      this.quotePriceRangeHealthOptions = [];
    },
    //确认保存报价配置
    quotationSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      console.log(this.allQuteData);
      let QuoteConfigparams = {
        assignQuoteMachineCondition: [], //	指定报价旧机机况
        "assignQuoteMachineHealthError": [],//指定报价旧机使用情况异常
        "assignQuoteMachineHealthNormal": [],//指定报价旧机使用情况正常
        assignQuotePriceRange: [], //指定价格段
        command: this.command, //谷歌验证码
        companyId: this.clustersofstars, //用商家列表的
        merchantId: this.contactmerchantId, //用关联商家的
        quotePriceType: this.quotePriceType, //价格使用 01 按靓机价 02 按预估价
        typeList: JSON.parse(JSON.stringify(this.allQuteData)).typeList,
      };
     //指定报价旧机使用情况正常
      this.quoteMachineHealthcheckedCities.forEach((itema) => {
        this.quoteMachineHealthcities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineHealthNormal.push(itemb.key);
          }
        });
      });
       //指定报价旧机使用情况异常
      this.quoteMachineHealthcheckedCitiesError.forEach((itema) => {
        this.quoteMachineHealthcitiesError.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineHealthError.push(itemb.key);
          }
        });
      });
      //	指定报价旧机机况
      this.quoteMachineConditioncheckedCities.forEach((itema) => {
        this.quoteMachineConditioncities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineCondition.push(itemb.key);
          }
        });
      });
      //指定价格段
      this.quotePriceRangeHealthcheckedCities.forEach((itema) => {
        this.quotePriceRangeHealthcities.forEach((itemb) => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuotePriceRange.push(itemb.key);
          }
        });
      });
      //没选中的品牌保存时删除
      QuoteConfigparams.typeList.forEach((irb) => {
        irb.brands = irb.brands.filter((item) => item.selected);
      });
      console.log(QuoteConfigparams);
      this.receiptTypeBtnLoading = true;
      // 保存合作报价配置
      _api
        .saveMiddleQuoteConfig(QuoteConfigparams)
        .then((res) => {
          this.receiptTypeBtnLoading = false;
          this.quotationDialogVisible = false;
          this.SetcategoryDloding = false;
          this.command = "";
          this.$message({
            message: "配置成功！",
            type: "success",
          });
          this.handleCurrentChange(this.pageNum);
        })
        .catch((ree) => {
          this.receiptTypeBtnLoading = false;
        });
    },

    //保存合作报价配置
    SetcategorySubmit() {
      if (!this.quoteMachineHealthcheckedCities.includes("其他")) {
        this.$message.error("旧机使用情况必须选择其他选项");
        return;
      }
      let phnoArr = [];
      let isQITA = true;
      this.allQuteData.typeList.forEach((item) => {
        if (item.name == "手机") {
          phnoArr = item.brands;
        }
      });
      console.log(phnoArr);
      if (phnoArr.length === 0) {
        console.log("不用判断");
      } else {
        phnoArr.forEach((its) => {
          if (its.id === -1 && !its.selected) {
            isQITA = false;
          }
        });
        if (
          this.quoteMachineConditioncheckedCities.includes("功能机/老年机") &&
          !isQITA
        ) {
          this.$message.error(
            "报价机况有功能机/老年机的,手机品牌必须选择其他选项！"
          );
          return;
        }
      }
      this.quotationDialogVisible = true;
    },
    //手机
    handleCheckAllChange(val) {
      console.log(val);
      this.mobilecheckedCities = val ? this.mobileOptions : [];
      this.mobileisIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      let checkedCount = value.length;
      this.mobilecheckAll = checkedCount === this.mobilecities.length;
      this.mobileisIndeterminate =
        checkedCount > 0 && checkedCount < this.mobilecities.length;
    },
    //平板
    tablethandleCheckAllChange(val) {
      console.log(val);
      this.tabletcheckedCities = val ? this.tabletOptions : [];
      this.tabletisIndeterminate = false;
    },
    tablethandleCheckedCitiesChange(value) {
      console.log(value);
      let tabletcheckedCount = value.length;
      this.tabletcheckAll = tabletcheckedCount === this.tabletcities.length;
      this.tabletisIndeterminate =
        tabletcheckedCount > 0 && tabletcheckedCount < this.tabletcities.length;
    },
    //笔记本
    laptophandleCheckAllChange(val) {
      console.log(val);
      this.laptopcheckedCities = val ? this.laptopOptions : [];
      this.laptopisIndeterminate = false;
    },
    laptophandleCheckedCitiesChange(value) {
      console.log(value);
      let laptopcheckedCount = value.length;
      this.laptopcheckAll = laptopcheckedCount === this.laptopcities.length;
      this.laptopisIndeterminate =
        laptopcheckedCount > 0 && laptopcheckedCount < this.laptopcities.length;
    },
    //其他
    otherhandleCheckAllChange(val) {
      console.log(val);
      this.othercheckedCities = val ? this.otherOptions : [];
      this.otherisIndeterminate = false;
    },
    otherhandleCheckedCitiesChange(value) {
      console.log(value);
      let othercheckedCount = value.length;
      this.othercheckAll = othercheckedCount === this.othercities.length;
      this.otherisIndeterminate =
        othercheckedCount > 0 && othercheckedCount < this.othercities.length;
    },
    //选择报价机况
    quoteMachineConditionallChange(val) {
      console.log(val);
      if (!this.mobilecheckedCities.includes("其他") && val) {
        this.mobilecheckedCities.push("其他");
      }
      this.quoteMachineConditioncheckedCities = val
        ? this.quoteMachineConditionOptions
        : [];
      this.quoteMachineConditionisIndeterminate = false;
    },
    quoteMachineConditionChange(value) {
      console.log(value);
      if (
        value[0] == "功能机/老年机" &&
        !this.mobilecheckedCities.includes("其他")
      ) {
        this.mobilecheckedCities.push("其他");
      }
      let quoteMachineConditionCount = value.length;
      this.quoteMachineConditioncheckAll =
        quoteMachineConditionCount === this.quoteMachineConditioncities.length;
      this.quoteMachineConditionisIndeterminate =
        quoteMachineConditionCount > 0 &&
        quoteMachineConditionCount < this.quoteMachineConditioncities.length;
    },
    //选择旧机使用情况
    quoteMachineHealthallChange(val) {
      console.log(val);
      this.quoteMachineHealthcheckedCities = val
        ? this.quoteMachineHealthOptions
        : [];
      this.quoteMachineHealthisIndeterminate = false;
    },
    quoteMachineHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quoteMachineHealthcheckAll =
        quoteMachineHealthCount === this.quoteMachineHealthcities.length;
      this.quoteMachineHealthisIndeterminate =
        quoteMachineHealthCount > 0 &&
        quoteMachineHealthCount < this.quoteMachineHealthcities.length;
    },
     //选择旧机使用情况异常
     quoteMachineHealthallChangeError(val) {
      console.log(val);
      this.quoteMachineHealthcheckedCitiesError = val
        ? this.quoteMachineHealthOptionsError
        : [];
      this.quoteMachineHealthisIndeterminateError = false;
    },
    quoteMachineHealthChangeError(value) {
      console.log(value);
      let quoteMachineHealthCountError = value.length;
      this.quoteMachineHealthcheckAllError =
        quoteMachineHealthCountError === this.quoteMachineHealthcitiesError.length;
      this.quoteMachineHealthisIndeterminateError =
        quoteMachineHealthCountError > 0 &&
        quoteMachineHealthCountError < this.quoteMachineHealthcitiesError.length;
    },
    //选择价位段
    quotePriceRangeHealthallChange(val) {
      console.log(val);
      this.quotePriceRangeHealthcheckedCities = val
        ? this.quotePriceRangeHealthOptions
        : [];
      this.quotePriceRangeHealthisIndeterminate = false;
    },
    quotePriceRangeHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quotePriceRangeHealthcheckAll =
        quoteMachineHealthCount === this.quotePriceRangeHealthcities.length;
      this.quotePriceRangeHealthisIndeterminate =
        quoteMachineHealthCount > 0 &&
        quoteMachineHealthCount < this.quotePriceRangeHealthcities.length;
    },
    onStoresClose() {
      this.configStoreshow = false;
      this.StorespageNum = 1;
    },
    //合作门店弹窗
    configStoresFn(row) {
      this.configStoreshow = true;
      this.StoresmerchantId = row.merchantId;
      this.StorespageNum = 1;
      _api
        .findInquiryMerchant({
          merchantId: row.merchantId,
          companyId: this.clustersofstars,
          pageNum: 1,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res, "门店");
          this.configStoresdata = res.data.records;
          this.StorespageNum = res.data.current;
          this.Storestotal = res.data.total;
        });
    },

    handleStoresChange(val) {
      if (val) {
        this.StorespageNum = val;
      } else {
        this.StorespageNum = 1;
      }

      _api
        .findInquiryMerchant({
          merchantId: this.StoresmerchantId,
          companyId: this.clustersofstars,
          pageNum: this.StorespageNum,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res, "门店");
          this.configStoresdata = res.data.records;
          this.StorespageNum = res.data.current;
          this.Storestotal = res.data.total;
        });
    },

    handleBtn(val) {
      this.title = "查看返利设置";
      if (this.dialogValue) {
        this.options = [];
        this.dialogValue = "";
      }
      this.showAmount = true;
      this.DialogDataList = [];
      this.tableColumns = [];
      this.total = 0;
      this.deleteVisible = true;
      this.navShow = true;
      this.companyId = val.id;
      const params = {
        companyId: this.companyId,
        merchantId: this.merchantId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.getMiddleAdoptMerchant(params).then((res) => {
        if (res.code === 1) {
          this.tableColumns = [
            { slotName: "priceRange" },
            { slotName: "operation" },
            { slotName: "operate" },
          ];
          this.options = res.data;
          this.dialogSelect();
        }
      });
    },
    dialogSelect(val) {
      let index = 0;
      //merchantCompanyId
      if (!val) {
        this.dialogValue = this.options[0].merchantName;
        this.merchantId = this.options[0].merchantId;
      } else {
        this.merchantId = val;
        this.options.forEach((item, i) => {
          if (item.merchantId === val) {
            index = i;
          }
        });
      }
      this.merchantCompanyId = this.options[index].merchantCompanyId;

      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyId: this.companyId,
        merchantId: this.merchantId,
      };
      _api.unionMerchantProfit(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;

          console.log(this.DialogDataList);
          this.total = res.data.total;
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      if (this.title === "关联商家") {
        this.pageNum = val;
        this.companyId = this.dialogParams.companyId;
        const params = {
          // 新加参数
          merchantName: this.umerChantName,
          middleStatus: this.cooperationState,
          isOpenQuoteChannelServerFee: this.isServiceFeeOpen, //是否开启报价服务费
          receiptType: this.Receiver, //收货方
          // ********
          companyId: this.companyId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        this.unionList(params);
      } else {
        const paramsGL = {
          pageNum: val,
          pageSize: this.pageSize,
          companyId: this.companyId,
          merchantId: this.merchantId,
        };
        _api.unionMerchantProfit(paramsGL).then((res) => {
          if (res.code === 1) {
            this.DialogDataList = res.data.records;
            this.total = res.data.total;
          }
        });
      }
    },
    //关联商家弹窗
    handleDialog(vals) {
      console.log(vals);
      this.currentRow = vals;
      this.title = "关联商家";
      // 置空赛选条件
      this.umerChantName = "";
      this.cooperationState = "";
      this.isServiceFeeOpen = null;
      this.Receiver = "";
      // *************
      this.DialogDataList = [];
      this.total = 0;
      this.deleteVisible = true;
      this.showAmount = false;
      this.tableColumns = [
        { slotName: "merchantName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "operationAddress" },
        { label: "入驻时间", prop: "createTime" },
        { label: "首次合作时间", prop: "firstAgreeTime" },
        { label: "累计交易（笔）", prop: "recycles" },
        { slotName: "applyStatus" },
        { slotName: "configStores" },
        { slotName: "Ownedchannel" },
        { slotName: "quoteMachineType" },
        { slotName: "uniformconfiguration" },
        { slotName: "logistics" },
        { slotName: "receiptType" },
        { slotName: "isInside" },
        { slotName: "serverFee" },
        { slotName: "canCallerMerchant" },
        { slotName: "isDiffFund" },
        { slotName: "Virtualquotation" },
        { slotName: "offerOperation" },
      ];
      this.clustersofstars = vals.id;
      this.pageNum = 1;
      const params = {
        merchantName: this.umerChantName, //回收商名称
        middleStatus: this.cooperationState,
        isOpenQuoteChannelServerFee: this.isServiceFeeOpen, //是否开启报价服务费
        receiptType: this.Receiver, //收货方
        companyId: vals.id,
        pageNum: this.pageNum,
        // pageSize:2
      };
      this.$store.commit("tagsView/DIALOG_PARAMS", params);
      console.log("unionList");
      this.unionList(params);
      console.log(this.DialogDataList, "handleDialog");
    },
    // 关联商家列表查询
    unionDialog() {
      const params = {
        merchantName: this.umerChantName, //回收商名称
        middleStatus: this.cooperationState,
        isOpenQuoteChannelServerFee: this.isServiceFeeOpen, //是否开启报价服务费
        receiptType: this.Receiver, //收货方
        companyId: this.currentRow.id,
        pageNum: this.pageNum,
        // pageSize:2
      };
      console.log(params, "params");
      this.unionList(params);
    },
    //关联商家列表请求
    unionList(params) {
      _api.unionMerchantList(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    onClose() {
      this.deleteVisible = false;
      this.currentRow = {};
    },
    handleEdit(row) {
      this.$set(row, "edit", true);
    },
    handleDelete(row) {
      this.$confirm(
        "删除设置将影响门店商家报价数据，请谨慎操作。删除前请务必确认已与门店沟通。",
        "说明",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.quoteProfileListOper(row, "03", "刪除");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSave(row) {
      if (!this.verifyRow(row)) {
        return;
      }
      this.$confirm(
        "如需修改返利设置，请务必先同门店商家确认。否则将影响商家订单数据统计。",
        "说明",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.quoteProfileListOper(
            row,
            row.add ? "01" : "02",
            row.add ? "新增" : "修改"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    quoteProfileListOper(row, type, text) {
      row.startToEndQuote = row.startQuote + "-" + row.endQuote;
      let _row = JSON.parse(JSON.stringify(row));
      delete _row.edit;
      delete _row.add;
      if (_row.androidType === "01") {
        _row.androidAmount = _row.androidAmount === "" ? 0 : _row.androidAmount;
      } else {
        _row.androidRatio = _row.androidRatio === "" ? 0 : _row.androidRatio;
      }

      if (_row.iphoneType === "01") {
        _row.iphoneAmount = _row.iphoneAmount === "" ? 0 : _row.iphoneAmount;
      } else {
        _row.iphoneRatio = _row.iphoneRatio === "" ? 0 : _row.iphoneRatio;
      }
      _api
        .quoteProfileListOper({
          id: row.id,
          merchantCompanyId: row.merchantCompanyId,
          quoteProfitInfo: _row,
          type,
        })
        .then((res) => {
          this.$message.success((res && res.msg) || text + "成功");
          this.handleCurrentChange(this.pageNum);
          this.$set(row, "edit", false);
        })
        .catch((err) => {
          this.handleCurrentChange(this.pageNum);
          this.$message.error((err.data && err.data.msg) || text + "失敗");
        });
    },
    handleSelectionChange() {},
    verifyRow(row) {
      let errorMsg = (msg) => {
        this.$message({
          message: msg,
          type: "warning",
        });
      };
      if (row.startQuote === "") {
        errorMsg("请输入最低返利金额");
        return false;
      }
      if (row.endQuote === "") {
        errorMsg("请输入最高返利金额");
        return false;
      }
      if (row.androidType === "02" && row.androidAmount === "") {
        errorMsg("请输入安卓固定返利金额");
        return false;
      }
      if (row.androidType === "01" && row.androidRatio === "") {
        errorMsg("请输入安卓返利比例");
        return false;
      }
      if (row.iphoneType === "02" && row.iphoneAmount === "") {
        errorMsg("请输入苹果固定返利金额");
        return false;
      }
      if (row.iphoneType === "01" && row.iphoneRatio === "") {
        errorMsg("请输入苹果返利比例");
        return false;
      }
      if (
        parseFloat(row.iphoneRatio) > 100 ||
        parseFloat(row.androidRatio) > 100 ||
        parseFloat(row.iphoneRatio) < 0 ||
        parseFloat(row.androidRatio) < 0
      ) {
        errorMsg("返利比例设置不正确");
        return false;
      }
      // if (parseFloat(row.iphoneAmount) > parseFloat(row.endQuote) || parseFloat(row.androidAmount) > parseFloat(row.endQuote) ||
      //     parseFloat(row.iphoneAmount) < 0 || parseFloat(row.androidAmount) < 0) {
      //   errorMsg('请设置0-' + parseFloat(row.endQuote) + '的固定返利金额')
      //   return false
      // }
      if (
        parseFloat(row.iphoneAmount) < 0 ||
        parseFloat(row.androidAmount) < 0
      ) {
        errorMsg("固定返利金额不能小于0");
        return false;
      }
      return true;
    },
    addSetting() {
      let { DialogDataList } = this;
      DialogDataList.push({
        androidAmount: "",
        androidRatio: "",
        androidType: "02",
        companyId: this.companyId,
        endQuote: "",
        id: "",
        iphoneAmount: "",
        iphoneRatio: "",
        iphoneType: "02",
        merchantCompanyId: this.merchantCompanyId,
        merchantId: this.merchantId,
        startQuote: "",
        startToEndQuote: "",
        edit: true,
        add: true,
      });
    },
    // 内部回收
    switchInsideBiz(row) {
      this.insideType = row.isInside;
      this.insideTitle = row.isInside ? "启用提示" : "关闭提示";
      this.rowCId = row.companyId;
      this.rowMId = row.merchantId;
      this.insideDialogVisible = true;
    },
    //虚拟报价
    switVirtualation(row) {
      this.unifytName = row.merchantName;
      this.isOpenMockQuote = row.isOpenMockQuote;
      this.VirtualMachineTitle = row.isOpenMockQuote ? "启用提示" : "关闭提示";
      this.rowCId = row.companyId;
      this.rowMId = row.merchantId;
      this.VirtualDialogVisible = true;
    },
    // 弹框关闭
    VirtualMachineClosed() {
      this.VirtualDialogVisible = false;
      this.command = "";
      this.handleCurrentChange(this.pageNum);
    },
    VirtualMachineSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.insideBtnLoading = true;
      _api
        .openmockQuote({
          companyId: this.rowCId,
          merchantId: this.rowMId,
          isOpenMockQuote: this.isOpenMockQuote,
          command: this.command,
        })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.VirtualDialogVisible = false;
          this.insideBtnLoading = false;
        })
        .catch(() => {
          this.insideBtnLoading = false;
        });
    },
    // 统一配置
    switchunification(row) {
      this.unifytName = row.merchantName;
      this.isDefaultQuoteMachine = row.isDefaultQuoteMachine;
      this.QuoteMachineTitle = row.isDefaultQuoteMachine
        ? "启用提示"
        : "关闭提示";
      this.rowCId = row.companyId;
      this.rowMId = row.merchantId;
      this.QuoteMachineDialogVisible = true;
    },
    // 弹框关闭
    QuoteMachineClosed() {
      this.QuoteMachineDialogVisible = false;
      this.command = "";
      this.handleCurrentChange(this.pageNum);
    },
    QuoteMachineSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.insideBtnLoading = true;
      _api
        .openDefaultQuote({
          companyId: this.rowCId,
          merchantId: this.rowMId,
          isDefaultQuoteMachine: this.isDefaultQuoteMachine,
          command: this.command,
        })
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.QuoteMachineDialogVisible = false;
          this.insideBtnLoading = false;
        })
        .catch(() => {
          this.insideBtnLoading = false;
        });
    },
    // 弹框关闭
    insideClosed() {
      this.insideDialogVisible = false;
      this.command = "";
      this.handleCurrentChange(this.pageNum);
    },
    insideSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.insideBtnLoading = true;
      _api
        .updateMiddleIsInside({
          companyId: this.rowCId,
          merchantId: this.rowMId,
          isOpen: this.insideType,
          command: this.command,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(res.msg || "操作成功");
            this.insideDialogVisible = false;
          } else {
            this.$message.success(res.msg || "操作成功");
            this.insideDialogVisible = false;
          }
          this.insideBtnLoading = false;
        })
        .catch(() => {
          this.insideBtnLoading = false;
        });
    },
    // 关闭/开启报价
    offerOperation(row) {
      this.relationRow = row;
      this.offerDialogShow = true;
    },
    // 设置回收商为指定报价
    setAssign(row) {
      this.relationRow = row;
      this.setAssignShow = true;
    },
    // 报价操作弹框关闭
    offerDialogClose() {
      this.command = "";
      this.relationRow = {};
      this.procedures = "";
    },
    setAssignDialogClose() {
      this.command = "";
      this.relationRow = {};
    },
    // 报价操作弹框确认按钮
    offerDialogSure() {
      if (
        this.currentRow.payApplyStatus == "02" &&
        this.relationRow.companyIsEnable == "02" &&
        !this.procedures
      ) {
        this.$message.error("请选择手续费承担方");
        return false;
      }
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return false;
      }
      let params = {
        command: this.command,
        companyId: this.currentRow.id,
        merchantId: this.relationRow.merchantId,
      };
      this.offerDialogBtnLoading = true;
      if (this.relationRow.companyIsEnable == "01") {
        _api
          .closeQuote(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.offerDialogShow = false;
              this.handleCurrentChange(this.pageNum);
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
      } else {
        params.processFeeBear = this.procedures;
        _api
          .saveUnionMerchant(params)
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.offerDialogShow = false;
              this.handleCurrentChange(this.pageNum);
            }
            this.offerDialogBtnLoading = false;
          })
          .catch(() => {
            this.offerDialogBtnLoading = false;
          });
      }
    },
    numberCheckData(prop) {
      var str = this[prop];
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (str > 100) {
        str = 100;
      }
      this[prop] = str;
    },
    // 设置收货方
    setReceiptType(row) {
      console.log(3333, row);
      // 回收商id
      this.merchantId = row.merchantId;
      // 收货地址类型
      this.receiptType = row.receiptType;

      // 查看地址
      if (!this.currentRow.isOut) {
        // 修改
        this.distributeShow = true;
      } else {
        this.receiptTypeDialogVisible = true;
      }
      this.queryAddress();
      this.receiptTypeChangeAddress();
    },
    // 设置收货方弹框关闭
    receiptTypeClosed() {
      this.command = "";
    },
    //设置收货方弹框确定
    receiptTypeSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        receiptType: this.receiptType,
        command: this.command,
        platformAddressId: null,
      };
      if (this.receiptType == "01") {
        params.platformAddressId = this.addressObj.id;
      } else {
        let arrIDS = this.addressPlatArr.filter((item) => item.selected);
        params.platformAddressId = arrIDS[0].id;
      }
      this.receiptTypeBtnLoading = true;
      _api
        .updateReceiptType(params)
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.receiptTypeDialogVisible = false;
          this.receiptTypeBtnLoading = false;
          this.handleCurrentChange(this.pageNum);
        })
        .catch(() => {
          this.receiptTypeBtnLoading = false;
        });
    },
    //设置指定报价
    assignSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      let params = {
        companyId: this.currentRow.id,
        isAssign: !this.relationRow.isAssign,
        merchantId: this.relationRow.merchantId,
        command: this.command,
      };
      this.assignBtnLoading = true;
      _api
        .setAssign(params)
        .then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.setAssignShow = false;
          this.assignBtnLoading = false;
          this.handleCurrentChange(this.pageNum);
        })
        .catch(() => {
          this.assignBtnLoading = false;
        });
    },
    queryAddress() {
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        receiptType: this.receiptType,
      };

      _api.queryAddress(params).then((res) => {
        if (res.code == 1) {
          this.addressObj = res.data;
          console.log("地址", res.data);
        }
      });
    },
    receiptTypeChange(e) {
      if (e == 1) {
        this.queryAddress();
      }
      if (e == 2) {
        this.receiptTypeChangeAddress();
      }
    },
    receiptTypeChangeAddress(e) {
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        receiptType: this.receiptType,
      };

      _api.queryplatFormAddress(params).then((res) => {
        if (res.code == 1) {
          console.log("平台地址", res.data);
          this.addressPlatArr = res.data;
        }
      });
      console.log(2345522, e);
    },
    changeTab(item, index) {
      this.addressPlatArr.forEach((el) => (el.selected = false));
      item.selected = true;
      this.active = index;
    },
    async switchCanCallerMerchant(row, canCallerMerchant) {
      this.canCallerMerchantFormData.canCallerMerchant = canCallerMerchant;
      this.canCallerMerchantFormData.companyId = row.companyId;
      this.canCallerMerchantFormData.merchantId = row.merchantId;
      this.canCallerMerchantFormData.unCallerMerchantStaffTypes =
        row.unCallerMerchantStaffTypes || [];
      if (canCallerMerchant === false) {
        this.canShowRealQuoteShow = true;
      } else {
        confirm({
          title: "开启提示",
          message: "是否确认开启允许查看回收商联系方式？",
          confirm: async () => {
            await this.setCanCallerMerchant();
            this.handleCurrentChange(this.pageNum);
          },
        });
      }
    },
    async btnRealQuote() {
      const { canCallerMerchantFormData } = this;
      const { canCallerMerchant, unCallerMerchantStaffTypes } =
        canCallerMerchantFormData;
      if (!unCallerMerchantStaffTypes.length) {
        this.$message.error("请选择不可查看角色");
        return;
      }
      await this.setCanCallerMerchant();
      this.canShowRealQuoteShow = false;
      this.handleCurrentChange(this.pageNum);
    },
    async setCanCallerMerchant() {
      const { canCallerMerchantFormData } = this;
      const { canCallerMerchant, unCallerMerchantStaffTypes } =
        canCallerMerchantFormData;
      await _api.setCanCallerMerchant(this.canCallerMerchantFormData);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.top_swith {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 切换
.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faultBtn {
  width: 340px;
  height: 32px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .left {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    background: #0981ff;
    border-radius: 20px 0 0 20px;
  }

  .left2 {
    text-align: center;
    color: white;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    background: #0981ff;
    border-radius: 0 20px 20px 0;
  }

  .right {
    text-align: center;
    color: #333;
    width: 50%;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 30px;
    height: 30px;
    border-radius: 20px;
  }
}

.SystemOffer-list {
  .addparto {
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff8080;
  }

  .fund-title {
    margin-bottom: 10px;
    padding: 0 4px;
    font-size: 14px;
  }

  .fund-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 20px 0;
  }

  .delet-btn {
    font-size: 14px;
    font-weight: 500;
    color: #ff0000;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    /deep/ .el-input {
      width: 300px;
    }
  }
}

.set_top_ck {
  span {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }
}

.categorySet {
  min-height: 82px;
  background: #ffffff;
  border: 1px solid #e4ecfd;
  border-radius: 10px;
  margin: 20px 0;

  .cs_center {
    padding: 0 25px;
    height: 34px;
    background: #eef3fe;
    border: 1px solid #e4ecfd;
    border-radius: 10px 10px 0px 0px;
  }
}

.dialog-btns {
  display: flex;
  justify-content: space-around;
}

.dialog-btn {
  cursor: pointer;
  user-select: none;
}

.dialog-btn.edit,
.dialog-btn.save {
  color: #0033cc;
}

.dialog-btn.delete {
  color: #ff3300;
}

/deep/ .el-input-group__append {
  border: 1px solid #0981ff;
  background: #0981ff;
  color: #ffffff;
}

.address-box {
  background-color: #f5f6fa;
  color: #666;
  width: 400px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid transparent;
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;

  .andleShowstyle {
    border-radius: 15px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}

/deep/.el-checkbox {
  margin-bottom: 10px;
}

.selection_type {
  display: flex;
  align-items: center;
  margin-right: 30px;
  cursor: not-allowed;

  img {
    margin-right: 10px;
    width: 14px;
    height: 14px;
  }
}

.step {
  .step-title {
    display: flex;
    color: #ff687b;
    margin-bottom: 18px;
  }

  .fileName {
    /deep/.el-input {
      width: 260px;
    }

    /deep/.el-button {
      // border-radius: 10px;
      margin-right: 0px;
    }
  }

  .step-content {
    margin-bottom: 20px;
  }
}
.user_all_cont {
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e4ecfd;
  .uer_content {
    width: 960px;
    height: 48px;
    background: #ffffff;
    // border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #e4ecfd;
    .left_user {
      margin-right: 30px;
      width: 128px;
      padding-top: 10px;
      height: 48px;
      background: #eef3fe;
      // border-radius: 10px 0px 0px 0px;
      border: 1px solid #e4ecfd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right_uer {
      // border: 1px solid #e4ecfd;
      display: flex;
      align-items: center;
      padding-top: 10px;
      height: 48px;
    }
  }
}


.mb-10 {
  margin-bottom: 10px;
}

.dis_flex {
  display: flex;
}

.al_c {
  align-items: center;
}

.ju_sb {
  justify-content: space-between;
}
</style>
